
import React from 'react'

export default function MasFotos() {
    return (
        <div className="sus-frases2">
            <h4>
                Si tienes fotos del Padre Jorge, sería maravilloso si pudieras compartirlas para
                que podamos mostrar más de su vida. Envíalas a <b>santosubito@santo-subito.org</b>
            </h4>
        </div>
    );
}















